import React, { useMemo } from 'react';

import { IdFilterFields } from '../../../../../types';
import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import { useMaterialBrandsSelected } from '../../../hooks/useMaterialBrandsSelected';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { MaterialCache } from '../../../MaterialCache';

interface MaterialsBrandsFilterBadgeItem {
  id: string;
  label: string;
}

function MaterialsBrandsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const filterValue = filters?.[name]?.in || [];

  const { materialBrandsSelected } = useMaterialBrandsSelected({
    selectedIds: filterValue,
    cacheKey: MaterialCache.brandsSelectedFilterCacheKey(),
    initialFilters: {
      scope: [FetchCategoriesScopes.MATERIAL_BRANDS],
      id: { in: [] }
    },
    keepPreviousData: true
  });

  const materialBrandsSelectedData = useMemo<MaterialsBrandsFilterBadgeItem[]>(
    () =>
      materialBrandsSelected.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
    [materialBrandsSelected]
  );

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.brands.plural}
      items={materialBrandsSelectedData}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default MaterialsBrandsFilterBadge;
