import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ClientError } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  FetchMaterialBrandsSelectedQueryResponse,
  FETCH_MATERIAL_BRANDS_SELECTED_QUERY
} from '../../queries/fetchMaterialBrandsSelected.query';

import { fetchItems } from '../../../common/hooks/base/reactQuery/baseActions/fetchItems';

import { CategoryID } from '../../../categories/categoriesTypes';

import { parseRequestError } from '../../../../utils/parseRequestError';

import {
  FetchMaterialCategoriesCacheKey,
  FetchMaterialCategoriesPage,
  FetchMaterialCategoriesLimit,
  FetchMaterialCategoriesSort
} from '../../materialsTypes';

import {
  INITIAL_MATERIAL_BRANDS_SELECTED_FILTERS,
  INITIAL_MATERIAL_BRANDS_SELECTED_LIMIT,
  INITIAL_MATERIAL_BRANDS_SELECTED_PAGE,
  INITIAL_MATERIAL_BRANDS_SELECTED_SORT
} from '../../materialsConstants';

type MaterialsBrandsFilterSelectedErrorType = Error | ClientError;

interface MaterialsBrandsFilterSelectedResponse {
  categories: {
    nodes: FetchMaterialBrandsSelectedQueryResponse[];
  };
}

interface MaterialBrandsSelectedFilters {
  scope?: string[];
  id?: { in: CategoryID[] };
}

interface MaterialBrandsSelectedDefaultOptions {
  cacheKey: FetchMaterialCategoriesCacheKey;
  initialFilters: MaterialBrandsSelectedFilters;
  initialSort?: FetchMaterialCategoriesSort;
  initialLimit?: FetchMaterialCategoriesLimit;
  initialPage?: FetchMaterialCategoriesPage;
  keepPreviousData?: boolean;
  selectedIds?: CategoryID[];
}

const staleTime = 1000 * 60 * 60;

function useMaterialBrandsSelected({
  cacheKey,
  initialFilters = INITIAL_MATERIAL_BRANDS_SELECTED_FILTERS,
  initialSort = INITIAL_MATERIAL_BRANDS_SELECTED_SORT,
  initialLimit = INITIAL_MATERIAL_BRANDS_SELECTED_LIMIT,
  initialPage = INITIAL_MATERIAL_BRANDS_SELECTED_PAGE,
  keepPreviousData,
  selectedIds = []
}: MaterialBrandsSelectedDefaultOptions) {
  const [currentFilters, setCurrentFilters] =
    useState<MaterialBrandsSelectedFilters>(initialFilters);

  const currentParams = {
    filters: currentFilters,
    sort: initialSort,
    page: initialPage,
    limit: initialLimit
  };

  const { data, error } = useQuery<
    MaterialsBrandsFilterSelectedResponse,
    MaterialsBrandsFilterSelectedErrorType
  >(
    [cacheKey, currentParams],
    () =>
      fetchItems({
        query: FETCH_MATERIAL_BRANDS_SELECTED_QUERY,
        ...currentParams
      }),
    {
      enabled: !isEmpty(currentFilters?.id?.in),
      staleTime,
      keepPreviousData
    }
  );

  const queryResponseValue = data?.categories;

  const items =
    queryResponseValue?.nodes || <FetchMaterialBrandsSelectedQueryResponse[]>[];

  useEffect(() => {
    if (isEmpty(selectedIds) && isEmpty(currentFilters?.id?.in)) {
      return;
    }

    if (!isEqual(selectedIds, currentFilters.id?.in)) {
      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        id: { in: selectedIds }
      }));
    }
  }, [selectedIds, currentFilters, setCurrentFilters]);

  return {
    materialBrandsSelected: items,
    materialsBrandsSelectedErrorMessage: parseRequestError(error)
  };
}

export default useMaterialBrandsSelected;
