import { useInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_MATERIAL_BRANDS_FILTERS,
  INITIAL_MATERIAL_BRANDS_LIMIT,
  INITIAL_MATERIAL_BRANDS_SORT
} from '../../materialsConstants';

import {
  FetchMaterialCategoriesCacheKey,
  FetchMaterialCategoriesFilters,
  FetchMaterialCategoriesLimit,
  FetchMaterialCategoriesSort
} from '../../materialsTypes';

import {
  FetchMaterialBrandsQueryResponse,
  FETCH_MATERIAL_BRANDS_QUERY
} from '../../queries/fetchMaterialBrands.query';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface MaterialColorsDefaultOptions {
  cacheKey: FetchMaterialCategoriesCacheKey;
  initialFilters?: FetchMaterialCategoriesFilters;
  initialSort?: FetchMaterialCategoriesSort;
  initialLimit?: FetchMaterialCategoriesLimit;
  options?: {
    staleTime?: number;
  };
}

function useMaterialBrands({
  cacheKey,
  initialFilters = INITIAL_MATERIAL_BRANDS_FILTERS,
  initialSort = INITIAL_MATERIAL_BRANDS_SORT,
  initialLimit = INITIAL_MATERIAL_BRANDS_LIMIT
}: MaterialColorsDefaultOptions) {
  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    isFetchingNextPage,
    currentFilters,
    hasNextPage,
    loadMoreItems,
    filterItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<FetchMaterialBrandsQueryResponse>({
    cacheKey,
    query: FETCH_MATERIAL_BRANDS_QUERY,
    initialFilters,
    initialSort,
    initialLimit,
    scope,
    options
  });

  return {
    materialBrands: items,
    materialBrandsErrorMessage: itemsError,
    materialBrandsFetched: isFetched,
    materialBrandsIsPlaceholderData: isPlaceholderData,
    materialBrandsFetchingNextPage: isFetchingNextPage,
    materialBrandsFilters: currentFilters,
    hasNextMaterialBrandsPage: hasNextPage,
    loadMoreMaterialBrands: loadMoreItems,
    materialBrandsFilterSearchValue: currentFilters?.name?.ilike,
    filterMaterialBrands: filterItems,
    changeMaterialBrandsFilters: changeItemsFilters
  };
}

export default useMaterialBrands;
