import { useCallback } from 'react';

import {
  FetchMaterialsFilters,
  FetchMaterialsSort,
  FetchMaterialsLimit,
  FetchMaterialsGqlQuery,
  FetchMaterialsCacheKey,
  MaterialNanoID
} from '../../materialsTypes';
import { FetchItemCacheKey, FetchItemsGqlQuery, UUID } from '../../../../types';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_MATERIALS_FILTERS,
  INITIAL_MATERIALS_LIMIT,
  INITIAL_MATERIALS_SORT
} from '../../materialsConstants';

interface MaterialsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface MaterialsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

interface MaterialsDefaultOptions {
  cacheKey: FetchMaterialsCacheKey;
  query: FetchMaterialsGqlQuery;
  initialFilters?: FetchMaterialsFilters;
  initialSort?: FetchMaterialsSort;
  initialLimit?: FetchMaterialsLimit;
  options?: {
    cacheTime?: number;
  };
}

type MaterialsOptions = MaterialsDefaultOptions &
  (MaterialsWithoutPrefetchItemOptions | MaterialsWithPrefetchItemOptions);

const scope = 'materials';

function useMaterials<MaterialItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_MATERIALS_FILTERS,
  initialSort = INITIAL_MATERIALS_SORT,
  initialLimit = INITIAL_MATERIALS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: MaterialsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    clearItemsFiltersPersistInitial,
    sortItems,
    prefetchItem,
    limitItems
  } = useInfiniteIndexQuery<MaterialItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options,
    query,
    scope
  });

  return {
    materialsData: data,
    materials: items,
    materialsError: itemsError,
    materialsTotalCount: itemsTotalCount,
    materialsFetched: isFetched,
    materialsLoading: isLoading,
    materialsFetchingNextPage: isFetchingNextPage,
    materialsIsPlaceholderData: isPlaceholderData,
    materialsFilters: currentFilters,
    materialsSort: currentSort as FetchMaterialsSort,
    materialsPage: currentPage,
    materialsLimit: currentLimit,
    hasNextMaterialsPage: hasNextPage,
    updateMaterialCache: updateItemCache,
    loadMoreMaterials: loadMoreItems,
    filterMaterials: filterItems,
    changeMaterialsFilters: changeItemsFilters,
    clearMaterialsFilters: clearItemsFilters,
    clearMaterialsFiltersPersistInitial: clearItemsFiltersPersistInitial,
    sortMaterials: sortItems,
    limitMaterials: limitItems,
    prefetchMaterial: useCallback(
      (materialNanoId: MaterialNanoID) => {
        const nanoId = materialNanoId as unknown as UUID;
        prefetchItem(nanoId);
      },
      [prefetchItem]
    )
  };
}

export default useMaterials;
